@import "../configuration/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
#root {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

body,
html {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

.MuiTextField-root,
.MuiFormControl-root {
	margin: 10px !important;
}

.contentHeight {
	margin-left: $standardMargin;
	margin-right: $standardMargin;
	margin-top: $standardMargin;
	margin-bottom: $standardMargin;
	> div:first-of-type {
		height: 100%;
		width: 100%;
	}
}
.MuiCard-root {
	border-radius: $standardBorderRadius !important;
}
button {
	outline: 0px !important; // ? disables Tailwind outline
}
/*
.MuiTableContainer-root::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

.MuiTableContainer-root::-webkit-scrollbar {
	height: 5px;
	width: 10px;
	background-color: transparent;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
	border-radius: 20px;
	//-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #616161;
}
*/

.rightBox {
	margin-left: $itemMargin;
}

.centerBox {
	margin-left: $itemMargin;
	margin-right: $itemMargin;
}

.leftBox {
	margin-right: $itemMargin;
}

@media only screen and (max-width: $mobileWidth) {
	.contentHeight {
		//margin: 0 $standardMargin;
		/*>div{
            display: flex;
            flex-direction: column;
            align-items: center;
        }*/
	}
	.leftBox,
	.rightBox,
	.centerBox {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
}
