.bottom-navigation {
  -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.25);

  .MuiBottomNavigationAction-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: unset !important;
  }
}
